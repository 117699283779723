var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.chart && _vm.chart.type === 'graphic')?_c('v-card',[_c('v-card-text',[_c('Plotly',{staticClass:"PlotlyChart",staticStyle:{"width":"100%","padding-bottom":"100%"},attrs:{"data":[
        {
          title: 'watermark_1',
          z: _vm.contentOfChartInJSON,
          colorscale: _vm.colorScale(_vm.chart),
          showscale: false,
          type: 'heatmap'
        }
      ],"options":{ responsive: true },"layout":{
        name: '',
        ..._vm.layout,
        title: _vm.chart.title,
        responsive: true,
        type: 'rect'
      },"display-responsive":true,"responsive":true,"options-responsive":true}})],1)],1):(_vm.chart && _vm.chart.type === 'flux')?_c('v-card',[_c('v-card-text',[_c('Plotly',{staticStyle:{"width":"100%","padding-bottom":"100%"},attrs:{"data":[
        {
          x: _vm.contentOfChartInJSON.row1.x,
          y: _vm.contentOfChartInJSON.row1.y,
          type: 'scatter'
        },
        {
          x: _vm.contentOfChartInJSON.row2.x,
          y: _vm.contentOfChartInJSON.row2.y,
          type: 'scatter'
        },
        {
          x: _vm.contentOfChartInJSON.row3.x,
          y: _vm.contentOfChartInJSON.row3.y,
          type: 'scatter'
        }
      ],"options":{ responsive: true },"layout":{
        ..._vm.layout,
        title: 'Exampl',
        responsive: true
      },"display-responsive":true,"responsive":true,"options-responsive":true}})],1)],1):_c('div',[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"size":100,"color":"primary","indeterminate":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }